.stakeholders-table {
    display: flex;
    flex-direction: column;
}

.stakeholders-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pie-chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-container {
    flex: 2;
    margin-left: 20px;
}

.highlight {
    background-color: #f0f0f0;
}

@media (max-width: 768px) {
    .stakeholders-content {
        flex-direction: column;
    }

    .table-container {
        margin-left: 0;
        margin-top: 20px;
    }
}